import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { computeRange, Form, FormField, getDisplayableFields } from "./formsTypes";
import { DataFieldInput, Maybe } from "../generated/graphql";
import { TextMaskNumber } from "../../utils/TextMasks";

interface FieldProps {
  field: FormField;
  data: DataFieldInput;
  setData: (newDataField: DataFieldInput) => void;
  disabled?: boolean;
}

interface GdFieldProps extends FieldProps {
  globalData: Maybe<DataFieldInput>[];
}

const NumberField: FC<FieldProps> = ({ field, data, setData, disabled }) => (
  <TextField
    variant="outlined"
    label={field.title}
    value={data.value}
    onChange={(e) => setData({ ...data, value: e.target.value })}
    disabled={disabled}
    InputProps={{
      inputComponent: TextMaskNumber as any,
    }}
    fullWidth
  />
);

NumberField.defaultProps = { disabled: false };

const ChoiceField: FC<FieldProps> = ({ field, data, setData, disabled }) => (
  <FormControl fullWidth>
    <InputLabel id={`field-selector-${field.id}`}>{field.title}</InputLabel>
    <Select
      labelId={`field-selector-${field.id}`}
      value={data ? data.value : undefined}
      onChange={(e) => setData({ ...data, value: e.target.value })}
      variant="outlined"
      label={field.title}
      disabled={disabled}
      fullWidth
      style={{ textAlign: "left" }}>
      {field.choices?.map((fc) => (
        <MenuItem key={fc.name as string} value={fc.value as string}>
          {fc.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

ChoiceField.defaultProps = { disabled: false };

const GdFormField: FC<GdFieldProps> = ({ field, data, setData, globalData, disabled }) => {
  const { t } = useTranslation("project");
  if (field.type === "range") {
    const inputChoice = globalData.find((d) => d?.fieldId === field.choiceRanges?.fieldId)?.value.split("_")[0];
    const applicableRange = field.choiceRanges?.valuesRanges?.find((r) => r.fieldValue === inputChoice)
      ?.range as string;
    const computedChoices = computeRange(applicableRange).map((n) => ({ name: `${n}`, value: `${n}` }));
    return (
      <ChoiceField
        field={{ ...field, choices: computedChoices }}
        data={data}
        setData={setData}
        disabled={!inputChoice || inputChoice.length === 0}
      />
    );
  }

  if (field.type === "choice") {
    return <ChoiceField field={field} data={data} setData={setData} disabled={disabled} />;
  }

  if (field.titles && field.titles.length > 0) {
    const inputFieldId = field.titles[0].fieldId;
    const inputChoice = globalData.find((d) => d?.fieldId === inputFieldId)?.value.split("_")[0];
    const computedTitle = field.titles.find((ft) => ft.fieldValue === inputChoice)?.title;
    return (
      <NumberField
        field={{ ...field, title: computedTitle }}
        data={data}
        setData={setData}
        disabled={!inputChoice || inputChoice.length === 0}
      />
    );
  }

  if (field.minValue || field.maxValue) {
    let key = "between";
    if (field.minValue && !field.maxValue) key = "moreThan";
    if (!field.minValue && field.maxValue) key = "lessThan";
    return (
      <NumberField
        field={{
          ...field,
          title: `${field.title} (${t(key, { replace: { min: field.minValue, max: field.maxValue } })})`,
        }}
        data={data}
        setData={setData}
      />
    );
  }

  return <NumberField field={field} data={data} setData={setData} disabled={disabled} />;
};

GdFormField.defaultProps = { disabled: false };

interface FormsFieldsProps {
  form?: Form;
  data: Maybe<DataFieldInput>[];
  onDataChange: (newData: Maybe<DataFieldInput>[]) => void;
}

const emptyData: DataFieldInput = {
  fieldId: "",
  value: "",
};

const FormFields: FC<FormsFieldsProps> = ({ form, data, onDataChange }) => {
  const finalFields = getDisplayableFields(form?.fields || [], data);
  const applicableFields = finalFields.filter((f) => {
    if (f.showIf) {
      const dValue = data.find((d) => d?.fieldId === f.showIf?.fieldId)?.value;
      return f.showIf?.fieldValues?.findIndex((v) => v === dValue) !== -1;
    }
    return true;
  });
  const updateData = (newDataField: DataFieldInput): void => {
    let found = false;
    const newData = data.map((df) => {
      if (df?.fieldId === newDataField.fieldId) {
        found = true;
        return newDataField;
      }
      return df;
    });
    if (!found) newData?.push(newDataField);

    // Check if current field is an inputField for other ranges
    const subRangesId = form?.fields?.filter((f) => f.choiceRanges?.fieldId === newDataField.fieldId).map((f) => f.id);

    onDataChange(newData.filter((d) => subRangesId?.indexOf(d?.fieldId || "") === -1));
  };
  return (
    <>
      {applicableFields.map((ff) => (
        <GdFormField
          key={ff.id}
          field={ff}
          data={data?.find((d) => d?.fieldId === ff.id) || { ...emptyData, fieldId: ff.id }}
          setData={updateData}
          globalData={data}
        />
      ))}
    </>
  );
};

FormFields.defaultProps = {
  form: undefined,
};

export default FormFields;
