import { Computation, Maybe, Operation, Sector } from "../generated/graphql";
import { Form, formatValue, getDisplayableFields, formatNumber } from "./formsTypes";
import indForms, { activeForms as indActiveForms } from "./data/indForms";
import agriForms, { activeForms as agriActiveForms } from "./data/agriForms";
import batForms, { activeForms as batActiveForms } from "./data/batForms";
import traForms, { activeForms as traActiveForms } from "./data/traForms";
import resForms, { activeForms as resActiveForms } from "./data/resForms";
import barForms, { activeForms as barActiveForms } from "./data/barForms";
import { getMetDomTom } from "../dataConvertors";

export const getFormsForSector = (sector?: Maybe<Sector>, zipCode?: string): Form[] => {
  const metDomTom = zipCode ? getMetDomTom(zipCode) : 0;
  const metDomTomFormsFilter = (f: Form): boolean => !f.metDomTom || f.metDomTom === metDomTom;
  switch (sector) {
    case Sector.Ind:
      return indForms.filter(metDomTomFormsFilter);
    case Sector.Agri:
      return agriForms.filter(metDomTomFormsFilter);
    case Sector.Bat:
      return batForms
        .filter(metDomTomFormsFilter)
        .concat(
          indForms.filter(
            (f) =>
              f.id === "IND-BA-112" ||
              f.id === "IND-UT-113" ||
              f.id === "IND-UT-115" ||
              f.id === "IND-UT-116" ||
              f.id === "IND-UT-117" ||
              f.id === "IND-UT-136",
          ),
        );
    case Sector.Tra:
      return traForms.filter(metDomTomFormsFilter);
    case Sector.Res:
      return resForms.filter(metDomTomFormsFilter);
    case Sector.Bar:
      return barForms.filter(metDomTomFormsFilter);
    default:
      return [];
  }
};

export const getActiveFormsId = (sector?: Maybe<Sector>): string[] => {
  switch (sector) {
    case Sector.Ind:
      return indActiveForms;
    case Sector.Agri:
      return agriActiveForms;
    case Sector.Bat:
      return batActiveForms;
    case Sector.Tra:
      return traActiveForms;
    case Sector.Res:
      return resActiveForms;
    case Sector.Bar:
      return barActiveForms;
    default:
      return [];
  }
};

export const extractOperationsConfig = (
  sector: Maybe<Sector> | undefined,
  zipCode: string,
  operations: Operation[],
  prefix: string,
  success: () => void,
  failure: () => void,
  capaValue?: boolean,
  totalComputation?: Maybe<Computation>,
  cpePrefix?: string,
  bonusPrefix?: string,
): void => {
  const lines: string[] = [];
  operations.forEach((o) => {
    const form = getFormsForSector(sector, zipCode).find((f) => f.id === o.formId);
    const fields = getDisplayableFields(form?.fields || [], o.data);
    lines.push(
      `${prefix} ${o.id} - ${o.formId}${o.machineName ? ` (${o.machineName})` : ""}${
        capaValue
          ? ` : ${formatValue(o.computation?.capacity, true, 3, true)} MWhca${
              o.computation?.valuation === "0" ? "" : ` / ${formatValue(o.computation?.valuation, false, 2, true)} €`
            }`
          : ""
      }`,
    );
    fields.forEach((ff) => {
      lines.push(
        `- ${
          ff.titles
            ? ff.titles.find((ft) => ft.fieldValue === o.data?.find((d) => d?.fieldId === ft.fieldId)?.value)?.title
            : ff.title
        } : ${
          ff.type === "number" || ff.type === "range"
            ? formatValue(o.data.find((d) => d?.fieldId === ff.id)?.value, true, 3)
            : ff.choices?.find((fc) => fc.value === o.data.find((d) => d?.fieldId === ff.id)?.value)?.name
        }`,
      );
    });
    if (parseFloat(o.bonus || "1") !== 1) {
      lines.push(`${bonusPrefix}${parseFloat(o.bonus || "")}`);
    }
  });
  if (capaValue && totalComputation && cpePrefix && totalComputation?.cpeBonus) {
    lines.push(
      `${cpePrefix} : ${formatNumber(
        (parseFloat(totalComputation.capacity) / (1 + parseFloat(totalComputation.cpeBonus) / 100)) *
          (parseFloat(totalComputation.cpeBonus) / 100),
        3,
        true,
      )} MWhca / ${formatNumber(
        (parseFloat(totalComputation.valuation) / (1 + parseFloat(totalComputation.cpeBonus) / 100)) *
          (parseFloat(totalComputation.cpeBonus) / 100),
        2,
        true,
      )} €`,
    );
  }
  navigator.clipboard.writeText(lines.join("\n")).then(success, failure);
};
