import { TextField } from "@mui/material";
import { CSSProperties, ChangeEvent, FC, useEffect, useState } from "react";
import { formatPhoneNumber } from "../data/forms/formsTypes";

interface PhoneTextFieldProps {
  value: string;
  label: string;
  onChange: (n: string) => void;
  style?: CSSProperties;
}

const PhoneTextField: FC<PhoneTextFieldProps> = ({ value, label, onChange, style }) => {
  const [formattedValue, setFormattedValue] = useState(formatPhoneNumber(value));

  useEffect(() => {
    setFormattedValue(formatPhoneNumber(value));
  }, [value]);
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const rawValue = event.target.value.replace(/ /g, "").replace(/-/g, "").replace(/\./g, "");
    if (!new RegExp("^[0-9 +]*$").test(rawValue)) return;
    const newFormattedValue = formatPhoneNumber(rawValue);
    setFormattedValue(newFormattedValue);
    onChange(rawValue);
  };

  const maxLength = (): number => {
    if (formattedValue.startsWith("0")) return 14;
    if (formattedValue.startsWith("+33")) return 16;
    if (
      formattedValue.startsWith("+590") ||
      formattedValue.startsWith("+262") ||
      formattedValue.startsWith("+594") ||
      formattedValue.startsWith("+596")
    )
      return 17;
    if (formattedValue.startsWith("+352") || formattedValue.startsWith("+32")) return 15;
    if (formattedValue.startsWith("+41")) return 16;
    return 17;
  };

  return (
    <TextField
      style={style}
      label={label}
      value={formattedValue}
      inputProps={{
        maxLength: maxLength(),
      }}
      onChange={handleChange}
      autoComplete="off"
    />
  );
};

PhoneTextField.defaultProps = {
  style: undefined,
};

export default PhoneTextField;
