import { ArrowBack } from "@mui/icons-material";
import { Button, IconButton, Modal, Paper, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GdButton from "../../utils/GdButton";
import WarningCard from "../../utils/WarningCard";
import { DataFieldInput, Maybe, OperationInput, Sector } from "../generated/graphql";
import FormFamily from "./FormFamily";
import FormFields from "./FormFields";
import { getActiveFormsId, getFormsForSector } from "./FormHelper";
import { Form, getDisplayableFields } from "./formsTypes";

interface FormEditModalProps {
  open: boolean;
  onClose: () => void;
  onAdd: (formId: string, data: Maybe<DataFieldInput>[], isReplacement: boolean, isCpeScoped?: boolean) => void;
  onUpdate: (newOperation: OperationInput) => void;
  sector: Sector;
  zipCode: string;
  operation?: OperationInput;
}

const emptyOperation: OperationInput = {
  id: "",
  formId: "",
  data: [],
  isReplacement: false,
  isCpeScoped: false,
};

const FormEditModal: FC<FormEditModalProps> = ({ open, onClose, onAdd, onUpdate, operation, sector, zipCode }) => {
  const [family, setFamily] = useState<string | null>(null);
  const [newOperation, setNewOperation] = useState(operation || emptyOperation);
  const { t } = useTranslation("project");

  useEffect(() => {
    setNewOperation(operation || emptyOperation);
  }, [operation]);

  const onValidate = (): void => {
    if (newOperation.id.length > 0) onUpdate(newOperation);
    else
      onAdd(newOperation.formId, newOperation.data, newOperation.isReplacement, newOperation.isCpeScoped || undefined);
    setNewOperation(emptyOperation);
  };

  const onFormSelect = (form?: Form): void => {
    if (!form) return;
    setNewOperation({ ...emptyOperation, formId: form.id });
  };

  const forms = getFormsForSector(sector, zipCode);
  const selectedForm = forms?.find((frm) => frm.id === newOperation.formId);

  const formChooser = family ? (
    <>
      <div className="row" style={{ alignItems: "flex-start" }}>
        <IconButton onClick={() => setFamily(null)} style={{ marginRight: 16 }}>
          <ArrowBack />
        </IconButton>
        <div>
          <Typography variant="h6">{t("chooseForm")}</Typography>
          <Typography variant="subtitle2" className="form-edit-title">
            {t("incomplete")}
          </Typography>
        </div>
      </div>
      <div className="form-edit-main">
        {getActiveFormsId(sector).map((fId) => {
          const f = forms.find((frm) => frm.id === fId);
          if (f?.type !== family) return undefined;
          return (
            <Button
              key={f?.id || ""}
              variant="outlined"
              color="primary"
              fullWidth
              className="form-edit-choose-button"
              onClick={() => onFormSelect(f)}>
              {fId} : {f?.name}
            </Button>
          );
        })}
      </div>
    </>
  ) : (
    <FormFamily sector={sector} setFamily={setFamily} setForm={onFormSelect} zipCode={zipCode} />
  );

  const validateButtonLabel = t(newOperation.id.length > 0 ? "updateOperation" : "addThisOperation");
  const isFormComplete: boolean =
    getDisplayableFields(selectedForm?.fields || [], newOperation.data).findIndex(
      (ff) => newOperation.data.findIndex((d) => d?.fieldId === ff.id && d?.value.length > 0) === -1,
    ) === -1;
  const isMinMaxOk: boolean =
    getDisplayableFields(selectedForm?.fields || [], newOperation.data).findIndex(
      (ff) =>
        newOperation.data.findIndex(
          (d) =>
            d?.fieldId === ff?.id &&
            (Number.parseFloat(d?.value || "0") < (ff?.minValue || 0) ||
              Number.parseFloat(d?.value || "0") > (ff?.maxValue || 1000000000000)),
        ) !== -1,
    ) === -1;

  const dataFiller = (
    <>
      <div className="row">
        {newOperation.id.length > 0 ? undefined : (
          <IconButton onClick={() => setNewOperation(emptyOperation)} style={{ marginRight: 16 }}>
            <ArrowBack />
          </IconButton>
        )}
        <div className="form-chosen-title">
          <Typography variant="h6">{selectedForm?.id}</Typography>
          <Typography variant="subtitle2">{selectedForm?.name}</Typography>
        </div>
        <GdButton label={t("seeForm")} onClick={() => window.open(selectedForm?.formUrl as string)} />
      </div>
      <div className="form-edit-main">
        <FormFields
          form={selectedForm}
          data={newOperation.data}
          onDataChange={(newData) => setNewOperation({ ...newOperation, data: newData })}
        />
        <div className="row">
          <Typography>{t("")}</Typography>
        </div>
        <WarningCard projectLabelKey="operationValuesWarning" className="margin-bottom margin-top" />
      </div>
      <GdButton label={validateButtonLabel} disabled={!isFormComplete || !isMinMaxOk} onClick={onValidate} />
    </>
  );
  return (
    <Modal
      open={open}
      onClose={() => {
        setNewOperation(emptyOperation);
        onClose();
      }}
      className="project-modal-root form-edit">
      <Paper className="project-modal-paper">{newOperation.formId.length === 0 ? formChooser : dataFiller}</Paper>
    </Modal>
  );
};

FormEditModal.defaultProps = {
  operation: undefined,
};

export default FormEditModal;
