import { getFormsForSector } from "../data/forms/FormHelper";
import { Form } from "../data/forms/formsTypes";
import { Operation, ProcedureStatus, Project, Sector } from "../data/generated/graphql";

export const dontTouchComputationOnProject = (project: Project): boolean =>
  Boolean(
    project.convention &&
      project.convention.status !== ProcedureStatus.Refused &&
      project.convention.status !== ProcedureStatus.Expired &&
      project.convention.status !== ProcedureStatus.Asked,
  );

export const isLatestForm = (operation: Operation, sector: Sector, zipCode: string): boolean => {
  const form = getFormsForSector(sector, zipCode).find((f) => f.id === operation.formId);
  return Boolean(
    (!form?.startDate ||
      !operation.computation?.computationDate ||
      form?.startDate < (operation.computation?.computationDate || "2023-09-30T01:00:00.000Z")) &&
      !operation.computation?.validity?.toLowerCase().includes("error"),
  );
};

export const getApplicableForm = (operation: Operation, sector: Sector, zipCode: string): Form | undefined => {
  const form = getFormsForSector(sector, zipCode).find((f) => f.id === operation.formId);
  if (!isLatestForm(operation, sector, zipCode)) return form?.history?.[0];
  return form;
};

export const isProjectObsolete = (project: Project): boolean => {
  if (dontTouchComputationOnProject(project)) return false;

  return (
    (project.operations || []).findIndex(
      (o) => o && !isLatestForm(o, project.details?.sector || Sector.Ind, project.client?.company?.zipCode || ""),
    ) !== -1
  );
};
