import { Add, Article } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";
import { ProjectContext } from "../../data/contexts/ProjectContext";
import { operationsToOperationsInput } from "../../data/dataConvertors";
import FormEditModal from "../../data/forms/FormEditModal";
import { extractOperationsConfig } from "../../data/forms/FormHelper";
import OperationsViewer from "../../data/forms/OperationsViewer";
import { DataFieldInput, Maybe, Operation, OperationInput, Project, Sector } from "../../data/generated/graphql";
import CenteredContent from "../../utils/CenteredContent";
import GdButton from "../../utils/GdButton";
import { log, Lvl } from "../../utils/log";
import MachineNamesModal, { OperationName } from "../components/MachineNamesModal";
import ModifyButton from "../components/ModifyButton";

import "./FormsSelector.css";
import { dontTouchComputationOnProject } from "../ProjectHelper";

interface FormsSelectorProps {
  project: Project;
  nextStep: (event?: React.MouseEvent) => void;
  readOnly: boolean;
}

const FormsSelector: FC<FormsSelectorProps> = ({ project, nextStep, readOnly }) => {
  const [newOperations, setNewOperations] = useState((project.operations as Operation[]) || []);
  const [updating, setUpdating] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [namesModalOpen, setNamesModalOpen] = useState(false);
  const [modified, setModified] = useState(false);
  const [editedOperation, setEditedOperation] = useState<OperationInput | null>(null);
  const { updateProject, updateError } = useContext(ProjectContext);
  const { t } = useTranslation("project");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setNewOperations((project.operations as Operation[]) || []);
  }, [project.operations]);

  const validate = async (inOperations?: OperationInput[]): Promise<void> => {
    const operations = inOperations || newOperations;
    // Check if there are duplicated forms without machineNames
    let found = false;
    const formIds: string[] = [];
    operations.forEach((no) => {
      if (formIds.indexOf(no.formId) !== -1 && !no.machineName) {
        found = true;
      }
      formIds.push(no.formId);
    });
    if (found) {
      // Open machine names modal
      setNamesModalOpen(true);
    } else {
      setUpdating(true);
      setModified(false);
      try {
        const result = await updateProject({
          id: project.id,
          operations: operationsToOperationsInput(operations),
        });
        if (!result) {
          log("Error while updating project", Lvl.ERROR, updateError);
          enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
        } else {
          enqueueSnackbar(t("updateSuccess"), { variant: "success" });
          if (!project.totalComputation) nextStep();
        }
      } finally {
        setUpdating(false);
      }
    }
  };

  const submitNames = (names: OperationName[]): void => {
    const namedOperations = newOperations.map((o) => ({
      ...o,
      machineName: names.find((n) => n.operationId === o.id)?.machineName || o.machineName,
    }));
    setNewOperations(namedOperations);
    setNamesModalOpen(false);
    validate(namedOperations);
  };

  const success = (): void => {
    enqueueSnackbar(t("operationsConfigIsCopied"), { variant: "success" });
  };

  const failure = (): void => {
    enqueueSnackbar(t("couldNotWriteClipboard"), { variant: "error" });
  };

  const openModal = (o?: OperationInput): void => {
    if (o) setEditedOperation(o);
    setModalOpen(true);
  };

  const closeModal = (): void => {
    setModalOpen(false);
    setTimeout(() => setEditedOperation(null), 500);
  };

  const addOperation = (
    formId: string,
    data: Maybe<DataFieldInput>[],
    isReplacement: boolean,
    isCpeScoped?: boolean,
  ): void => {
    setModified(true);
    const nextId = `${newOperations.length + 1}`;
    setNewOperations([...newOperations, { id: nextId, formId, data, isReplacement, isCpeScoped }]);
    closeModal();
  };

  const updateOperation = (operationToUpdate: OperationInput): void => {
    setModified(true);
    const operationIndex = newOperations.findIndex((o) => o.id === operationToUpdate.id);
    setNewOperations([
      ...newOperations.slice(0, operationIndex),
      operationToUpdate,
      ...newOperations.slice(operationIndex + 1),
    ]);
    closeModal();
  };

  const duplicateOperation = (operationToDuplicate: OperationInput): void => {
    setModified(true);
    const nextId = `${newOperations.length + 1}`;
    setNewOperations([...newOperations, { ...operationToDuplicate, id: nextId, machineName: undefined }]);
  };

  const removeOperation = (operationToRemove: OperationInput): void => {
    setModified(true);
    let currentId = 0;
    const ops = newOperations.filter((o) => o.id !== operationToRemove.id);
    setNewOperations(
      ops.map((o) => {
        currentId += 1;
        return { ...o, id: `${currentId}` };
      }),
    );
  };

  if (!project.details?.sector) return <CenteredContent errorText={t("fillDetails")} />;

  const dontTouchOperations = dontTouchComputationOnProject(project);

  return (
    <div className="project-forms-root">
      {newOperations.length === 0 ? (
        <div className="project-forms-empty">
          <Typography variant="h6">{t("noOperationYet")}</Typography>
          {readOnly ? undefined : <GdButton label={t("addOperation")} onClick={() => openModal()} />}
        </div>
      ) : (
        <>
          <div className="project-forms-header">
            <Typography variant="h6" className="project-operations-label">
              {t("operations")}
            </Typography>
            <div>
              <Tooltip title={t("getOperationsConfig") as string}>
                <IconButton
                  onClick={() =>
                    extractOperationsConfig(
                      project.details?.sector,
                      project.client?.company?.zipCode || "",
                      newOperations,
                      t("operation"),
                      success,
                      failure,
                      false,
                      undefined,
                      undefined,
                      t("bonificationPrefix"),
                    )
                  }
                  color="primary"
                  size="large">
                  <Article />
                </IconButton>
              </Tooltip>
              {newOperations.length === 0 || readOnly ? undefined : (
                <Tooltip title={t("addAnotherOperation") as string}>
                  <IconButton onClick={() => openModal()} color="primary" size="large">
                    <Add />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="project-operations-viewer">
            <OperationsViewer
              sector={project.details?.sector}
              zipCode={project.client?.company?.zipCode || ""}
              operations={newOperations}
              editOperation={readOnly ? undefined : openModal}
              duplicateOperation={readOnly ? undefined : duplicateOperation}
              removeOperation={readOnly ? undefined : removeOperation}
              addCpeCheckbox={Boolean(project.details.cpeDuration)}
              updateOperationCpe={updateOperation}
              isReadOnly={readOnly}
              dontTouchOperations={dontTouchOperations}
            />
          </div>
          {readOnly ? (
            project.isReadOnly ? undefined : (
              <ModifyButton project={project} projectLabelKey="modifyForms" />
            )
          ) : (
            <GdButton
              label={t(project.totalComputation ? "global:save" : "validateNextstep")}
              onClick={() => validate()}
              isLoading={updating}
              disabled={newOperations.length === 0 || (Boolean(project.totalComputation) && !modified)}
            />
          )}
          <Prompt when={modified} message={t("promptNotSaved")} />
        </>
      )}
      <FormEditModal
        operation={editedOperation === null ? undefined : editedOperation}
        open={modalOpen}
        onClose={closeModal}
        onAdd={addOperation}
        onUpdate={updateOperation}
        sector={project.details?.sector as Sector}
        zipCode={project.client?.company?.zipCode || ""}
      />
      <MachineNamesModal
        open={namesModalOpen}
        onClose={() => setNamesModalOpen(false)}
        operations={newOperations}
        onSubmit={submitNames}
      />
    </div>
  );
};

export default FormsSelector;
